const initBlock = async function( $block ) {
	const $footer = $block;
	const $stickyContactBtn = $footer.find( '.sticky-contact-us' );
	const $siteWrapper = $( '.wp-site-blocks' );

	if ( $stickyContactBtn.length ) {
		const handleScroll = () => {
			const scrollTop = $( window ).scrollTop();
			const windowHeight = $( window ).height();
			const footerOffset = $footer.offset().top;

			if ( scrollTop + windowHeight >= footerOffset ) {
				$stickyContactBtn.fadeOut();
			} else {
				$stickyContactBtn.fadeIn();
			}
		};

		handleScroll();

		$( $siteWrapper ).on( 'scroll', handleScroll );
	}
};

themeUtils.loadBlock( initBlock, 'footer', '.page-footer' );
